import logo from "./logo.svg";
import Title from "./Title";
const Logo = () => {
  return (
    <>
      <a href="/">
        <img src={logo} className="logo" alt="logo" />
      </a>
      <Title />
    </>
  );
};

export default Logo;
