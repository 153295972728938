import "./Main.css";
//import Plesk from "./Plesk/Plesk";
const Main = () => {
  return (
    <main className="container my-md-4 bd-layout">
      <h1>List</h1>
      {/* <Plesk /> */}
    </main>
  );
};
export default Main;
