import "./Footer.css";

const Footer = () => {
  return (
    <footer className="bg-dark text-white">
      <div className="container px-3 py-2 ">
        Powered by <a href="https://reactjs.org/">React</a>
      </div>
    </footer>
  );
};
export default Footer;
