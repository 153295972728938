import "./Header.css";
import Logo from "./Logo";
const Header = () => {
  return (
    <header>
      <div class="px-3 py-2 bg-dark text-white">
        <div class="container">
          <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <Logo />
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
